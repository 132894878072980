.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.box {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
body {
  background-color: black; /* 設置整體背景為黑色 */
  color: white; /* 設置字體顏色為白色 */
}

/* 表格樣式 */
.ant-table {
  background-color: #333 !important; /* 表格背景 */
  color: white !important; /* 表格字體顏色 */
}

.ant-table-thead > tr > th {
  background-color: #444 !important; /* 表頭背景 */
  color: white !important; /* 表頭字體顏色 */
}

.ant-table-tbody > tr > td {
  background-color: #333 !important; /* 表格內容背景 */
  color: white !important; /* 表格內容字體顏色 */
}

/* DatePicker 和 RangePicker */
.ant-picker {
  background-color: #333 !important; /* 設置選擇器背景 */
  color: white !important; /* 設置字體顏色 */
  border: 1px solid #555 !important; /* 設置邊框顏色 */
}

.ant-picker-input > input {
  background-color: #333 !important; /* 設置輸入框背景 */
  color: white !important; /* 設置輸入框字體顏色 */
}

/* Input 組件 */
.ant-input {
  background-color: #333 !important; /* 設置輸入框背景 */
  color: white !important; /* 設置輸入框字體顏色 */
  border: 1px solid #555 !important; /* 設置邊框顏色 */
}

.ant-input::placeholder {
  color: rgba(255, 255, 255, 0.5) !important; /* 提示字顏色 */
}

/* Button 組件 */
.ant-btn {
  background-color: #444 !important; /* 設置按鈕背景 */
  color: white !important; /* 設置按鈕字體顏色 */
  border: none !important; /* 移除邊框 */
}

.ant-btn:hover {
  background-color: #555 !important; /* 鼠標懸停時的背景顏色 */
}

/* Pagination 樣式 */
.ant-pagination {
  background-color: #444 !important; /* 分頁背景 */
  color: white !important; /* 分頁字體顏色 */
}

.ant-pagination-item {
  background-color: #444 !important; /* 分頁按鈕背景 */
  color: white !important; /* 分頁按鈕字體顏色 */
}

.ant-pagination-item:hover {
  background-color: #555 !important; /* 鼠標懸停時的分頁按鈕背景 */
}

.ant-pagination-options {
  color: white !important; /* 分頁選項字體顏色 */
}

/* App.css */
.ant-table .ant-table-column-sorter .anticon,
.ant-table .ant-table-column-title .anticon {
    color: white !important; /* 讓圖標顏色為白色 */
}

/* 針對 Table 的過濾器和搜尋圖標 */
.ant-table .ant-table-column-sorter .anticon,
.ant-table .ant-table-column-title .anticon,
.ant-table .ant-table-filter-trigger .anticon {
    color: white !important; /* 讓圖標顏色為白色 */
}
/* App.css */

/* App.css */

/* 針對啟用過濾器時的圖標顏色 */
.ant-table .ant-table-column-title .ant-table-filter-trigger.active .anticon {
  color: #1890ff !important; /* 設置啟用過濾器時的圖標顏色為藍色 */
}

/* 針對過濾器下拉菜單的樣式 */
.ant-table .ant-table-column-title .ant-table-filter-trigger:hover .anticon {
  color: #1890ff !important; /* 設置懸停時的圖標顏色為藍色 */
}

/* 確保啟用過濾器時的圖標顏色 */
.ant-table .ant-table-filter-trigger.active .anticon {
  color: #1890ff !important; /* 設置啟用過濾器時的圖標顏色為藍色 */
}

/* 確保啟用過濾器的背景 */
.ant-table .ant-table-filter-trigger.active {
  background-color: rgba(24, 144, 255, 0.1) !important; /* 設置啟用過濾器的背景顏色 */
}

/* 懸停時的圖標顏色 */
.ant-table .ant-table-filter-trigger:hover .anticon {
  color: #1890ff !important; /* 設置懸停時的圖標顏色為藍色 */
}

